import React, { useState, useRef, useEffect, useCallback } from 'react';

import cs from 'classnames';

import IconAdd from '@/assets/icons/icon-add.svg';
import { FaqRecipeListComponent } from '@/components';
import { FaqItemDef } from '@/models/faq.types';

type FaqItemProps = FaqItemDef;

const FaqItem = ({ question, answer, recipes }: FaqItemProps) => {
  const [stateExpanded, setStateExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const contentWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setContentHeight(
      stateExpanded ? contentRef?.current?.scrollHeight ?? 0 : 0
    );
  }, [stateExpanded]);

  const onContentChange = useCallback(() => {
    setContentHeight(
      stateExpanded ? contentWrapperRef?.current?.scrollHeight ?? 0 : 0
    );
  }, [stateExpanded]);

  useEffect(() => {
    window.addEventListener('resize', onContentChange);
    return () => window.removeEventListener('resize', onContentChange);
  }, [onContentChange]);

  return (
    <>
      <h3>
        <button
          className="flex w-full p-5 text-left cursor-default pc:cursor-pointer"
          type="button"
          onClick={() => setStateExpanded(!stateExpanded)}
        >
          <span className="font-bold text-26 leading-none font-gothic-bold translate-y-1">
            Q.
          </span>
          <span className="font-bold text-base leading-26 ml-3 pr-4">
            {question}
          </span>
          <span
            className={cs(
              'ml-auto transition-transform duration-300 ease-in-out mt-[0.3125rem]',
              { 'rotate-[225deg]': stateExpanded }
            )}
          >
            <IconAdd />
          </span>
        </button>
      </h3>
      <div
        ref={contentRef}
        className="bg-gray-200 transition-height duration-300 ease-in-out overflow-hidden"
        style={{ height: contentHeight }}
      >
        <div className="flex items-start p-5" ref={contentWrapperRef}>
          <span className="font-bold text-26 leading-none font-gothic-bold translate-y-1">
            A.
          </span>
          <div className="ml-3">
            <span className="text-base leading-26">{answer}</span>
            {recipes && <FaqRecipeListComponent recipes={recipes} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqItem;
