import React from 'react';

import cs from 'classnames';

import IconFacebook from '@/assets/icons/icon-facebook.svg';
import IconLine from '@/assets/icons/icon-line.svg';
import IconTwitter from '@/assets/icons/icon-twitter.svg';

const SOCIAL_DATA = [
  {
    icon: <IconFacebook />,
    href: `https://www.facebook.com/sharer/sharer.php?u=${process.env.NEXT_PUBLIC_APP_URL}`,
  },
  {
    icon: <IconTwitter />,
    href: `https://www.twitter.com/intent/tweet?url=${process.env.NEXT_PUBLIC_APP_URL}`,
  },
  {
    icon: <IconLine />,
    href: `https://timeline.line.me/social-plugin/share?url=${process.env.NEXT_PUBLIC_APP_URL}`,
  },
] as const;

type FooterProps = {
  withSocial?: boolean;
  isInTopScreen?: boolean;
  isInInquiryScreen?: boolean;
};

const Footer = ({
  withSocial,
  isInTopScreen,
  isInInquiryScreen,
}: FooterProps) => (
  <footer
    className={cs('py-10 tl:py-12 text-center px-5', {
      'pb-28': isInTopScreen,
      'pt-15 tl:pt-13': isInInquiryScreen,
    })}
  >
    {withSocial && (
      <>
        <p className="text-21 tl:text-26 leading-none font-gothic-bold font-bold">
          SHARE
        </p>
        <ul className="flex-centered mt-4">
          {SOCIAL_DATA.map(({ icon, href }, idx) => (
            <li key={idx} className={cs({ 'ml-4': !!idx })}>
              <a
                href={href}
                target="_blank"
                rel="noreferrer"
                className="w-10 h-10 rounded-full bg-secondary hover:opacity-50 flex-centered cursor-default pc:cursor-pointer"
              >
                {icon}
              </a>
            </li>
          ))}
        </ul>
      </>
    )}
    <p
      className={cs('text-xs text-gray-300', {
        'mt-10 tl:mt-6': withSocial,
      })}
    >
      copyright © suntory holdings limited. all rights reserved.
    </p>
  </footer>
);

export default Footer;
