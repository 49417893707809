import '@/styles/globals.css';
import { ReactElement, ReactNode } from 'react';

import { Noto_Sans_JP } from '@next/font/google';
import localFont from '@next/font/local';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';

import { AxiosInterceptor } from '@/api/api';
import { Modal as OutdatedModal } from '@/components';

import { useAppStore } from '../store';

export type NextPageWithLayout<P = Record<string, never>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const notoSansJP = Noto_Sans_JP({
  display: 'swap',
  subsets: ['japanese'],
  weight: ['400', '500', '700', '900'],
});

const gothicBold = localFont({
  weight: '700',
  src: '../assets/fonts/TradeGothicLTPro-BdCn20.woff2',
});

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const { isOutDated, clearAllContactInfo } = useAppStore();

  const handleOutDated = () => {
    clearAllContactInfo();
    window.location.reload();
  };

  return getLayout(
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=5.0"
        />
      </Head>
      <style jsx global>
        {`
          :root {
            --noto-sans-font: ${notoSansJP.style.fontFamily};
            --gothic-bold-font: ${gothicBold.style.fontFamily};
          }
        `}
      </style>
      <AxiosInterceptor />
      <Component {...pageProps} />
      <OutdatedModal visible={isOutDated} onOk={handleOutDated} />
    </>
  );
}
