import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { ContactStoreDef, CommonStoreDef } from '@/models/inquiry.types';

import { common } from './common';
import { contact } from './contact';

type StoreState = ContactStoreDef & CommonStoreDef;

export const useAppStore = create<StoreState>()(
  persist(
    (...args) => ({
      ...contact(...args),
      ...common(...args),
    }),
    {
      name: 'contact',
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(([key]) => !['isOutDated'].includes(key))
        ),
    }
  )
);
