import { useEffect } from 'react';

import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import qs from 'query-string';

import { useAppStore } from '../store';

const api = axios.create({
  timeout: 30000,
  baseURL: process.env.NEXT_PUBLIC_API_HOST,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.NEXT_PUBLIC_API_KEY,
    'Accept-version': process.env.NEXT_PUBLIC_API_VERSION,
  },
  paramsSerializer: {
    serialize: (params) =>
      qs.stringify(params, { skipEmptyString: true, skipNull: true }),
  },
});

api.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => config,
  (error: AxiosError) => Promise.reject(error)
);

const AxiosInterceptor = () => {
  const { setIsOutDated } = useAppStore();

  useEffect(() => {
    const resInterceptor = (response: AxiosResponse) => response;
    const errInterceptor = (error: AxiosError) => {
      const errStatus = error?.response?.status || error?.code;
      if (errStatus === 426) {
        setIsOutDated(true);
        document.body.style.overflow = 'hidden';
      }
      return Promise.reject(error);
    };

    const interceptor = api.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    return () => api.interceptors.response.eject(interceptor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export { AxiosInterceptor };

export default api;
