import React from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';

import ImageDefaultOG from '@/assets/images/og.png';
import { METADATA } from '@/constants/metadata.constants';

type SEOProps = {
  isArticle?: boolean;
  noIndex?: boolean;
  noScalable?: boolean;
  author?: string;
  screenName: keyof typeof METADATA;
};

const SEO = ({
  noIndex,
  noScalable,
  isArticle,
  author,
  screenName,
}: SEOProps) => {
  const { pathname } = useRouter();

  const seo = {
    title: METADATA[screenName].title || '',
    description: METADATA[screenName].description || '',
    image: `${process.env.NEXT_PUBLIC_APP_URL}${ImageDefaultOG.src}`,
    url: `${process.env.NEXT_PUBLIC_APP_URL}${pathname}`,
  };

  const seoOGP = {
    author: author ?? '',
    title: 'TAG LIVE LABEL | SNAP DRINK：ラベル缶',
    url: process.env.NEXT_PUBLIC_APP_URL,
    description:
      'オリジナルラベル缶をその場で作成・販売できる法人向けサービスです。超小ロット・在庫リスクゼロ・短納期・初期費用0円でご導入いただけます。',
  };

  return (
    <Head>
      <title>{seo.title}</title>
      <link rel="canonical" href={seo.url} />
      <meta name="description" content={seo.description} />
      {seoOGP.author && (
        <>
          <meta name="author" content={seoOGP.author} />
          <meta property="og:author" content={seoOGP.author} />
          <meta name="twitter:author" content={seoOGP.author} />
        </>
      )}
      <meta name="image" content={seo.image} />
      <meta property="og:type" content={isArticle ? 'article' : 'website'} />
      <meta property="og:url" content={seoOGP.url} />
      <meta property="og:title" content={seoOGP.title} />
      <meta property="og:description" content={seoOGP.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:site_name" content="TAG LIVE LABEL | SNAP DRINK" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seoOGP.title} />
      <meta name="twitter:description" content={seoOGP.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta
        name="keywords"
        content="タグ ライブ ラベル, オリジナル, ラベル, イベント, 企画, 記憶に残る,ドリンク,販促用,名入れ,来客用,広告"
      />
      {noIndex && <meta name="robots" content="noindex,follow" />}
      {noScalable && (
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      )}
    </Head>
  );
};

export default SEO;
