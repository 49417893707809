import React from 'react';

import cs from 'classnames';

import IconInfo from '@/assets/icons/icon-info.svg';

import Button from '../Button';

type ModalProps = {
  visible: boolean;
  onOk: () => void;
};

const Modal = ({ visible, onOk }: ModalProps) => {
  const classNames = cs(
    'fixed bg-modal flex-centered transition-[visibility] transition-[opacity] duration-200 inset-0 z-50',
    { visible, 'invisible opacity-0': !visible }
  );

  const modalWrapperClassNames = cs(
    'mx-4 tl:mx-6 bg-white flex-1 rounded p-5 shadow-modal transition-scale duration-200 scale-0 max-w-[450px]',
    { 'scale-100': visible }
  );

  return (
    <div className={classNames}>
      <div className={modalWrapperClassNames}>
        <div className="flex items-center">
          <IconInfo />
          <p className="flex-1 ml-2 text-secondary">
            最新バージョンにアップデートしてください。
          </p>
        </div>
        <div className="mt-5 text-right">
          <Button btnType="primary" className="h-12 px-4" onClick={onOk}>
            再読み込み
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
