import React from 'react';

type SectionTitleProps = {
  title: string;
  description: string;
  id: string;
};

const SectionTitle = ({ title, description, id }: SectionTitleProps) => (
  <div className="text-center">
    <div id={id} className="-translate-y-20 pc:-translate-y-28"></div>
    <h2 className="text-2xl font-bold tl:text-32 leading-none font-gothic-bold">
      {title}
    </h2>
    <p className="leading-[1.875]">{description}</p>
  </div>
);

export default SectionTitle;
