import React from 'react';

import ImageTitleLogoPC from '@/assets/icons/result/title-logo-pc.svg';
import ImageTitleLogo from '@/assets/icons/result/title-logo.svg';

type ResultTitleProps = {
  title: string;
};

const ResultTitle = ({ title }: ResultTitleProps) => (
  <h1 className="text-center">
    <ImageTitleLogo className="tl:hidden mx-auto" />
    <ImageTitleLogoPC className="hidden tl:block mx-auto" />
    <p className="mt-5 text-lg font-bold leading-[1.6]">{title}</p>
  </h1>
);

export default ResultTitle;
