var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"DrekcLx5kl4KMsXQfgxx8"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import {
  Debug as DebugIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
  ReportingObserver as ReportingObserverIntegration,
} from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';
import dayjs from 'dayjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: parseFloat(
    process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE
  ),
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  integrations: [
    new ExtraErrorDataIntegration(),
    new DebugIntegration({ stringify: true }),
    new ReportingObserverIntegration(),
  ],
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  sampleRate: 1,
  attachStacktrace: true,
  debug: !process.env.IS_PRODUCTION_ENV,
  normalizeDepth: 10,
  initialScope: {
    tags: { system: 'Olb Landing Page' },
  },
  release: `LLD-LP_${dayjs().format('YYYY-MM-DD')}`,
});
