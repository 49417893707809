import { Fragment } from 'react';

import { FaqRecipesDef } from '@/models/faq.types';

type FaqRecipeItemProps = { recipe: FaqRecipesDef[number] };

const FaqRecipeItem = ({ recipe }: FaqRecipeItemProps) => {
  const { name, ingredients, ingredientsAndCharacteristics } = recipe;

  return (
    <li className="rounded-4 overflow-hidden text-sm leading-[1.8]">
      <div className="p-4 border-b border-grey-300 bg-grey-300">
        <h4 className="font-bold">{name}</h4>
      </div>
      <div className="p-4 border-b border-grey-300 bg-white">
        <h4 className="font-bold">原材料</h4>
        <p className="mt-1 text-justify">{ingredients}</p>
      </div>
      <div className="p-4 bg-white">
        <h4>
          <span className="font-bold">成分・特性</span>（100mlあたり）
        </h4>
        <dl className="grid grid-cols-2 mt-1 [&_dd]:justify-self-end">
          {ingredientsAndCharacteristics.map(
            ([ingredient, characteristics]) => (
              <Fragment key={ingredient}>
                <dt>{ingredient}</dt>
                <dd>{characteristics}</dd>
              </Fragment>
            )
          )}
        </dl>
      </div>
    </li>
  );
};

export default FaqRecipeItem;
