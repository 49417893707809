import React from 'react';

import { ContainerComponent, ButtonComponent } from '@/components';
import { handleRedirectNewTab } from '@/utils/redirect';

const FloatButtonSection = () => (
  <ContainerComponent className="fixed w-full bottom-5 tl:hidden z-10">
    <ButtonComponent
      icon={true}
      btnType="primary"
      className="w-full h-15 text-white relative"
      onClick={handleRedirectNewTab}
    >
      お問い合わせはこちら
    </ButtonComponent>
  </ContainerComponent>
);

export default FloatButtonSection;
