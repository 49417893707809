import React from 'react';

import { IntroduceContentDataDef } from '@/models/landingpage.types';

type IntroductionContentProps = { content: IntroduceContentDataDef };

const IntroductionContent = ({ content }: IntroductionContentProps) => {
  const { title, description, textNote, icon } = content;

  return (
    <div className="tl:min-h-[8.75rem] pc:min-h-[6.75rem] flex flex-col justify-start">
      <h3 className="flex items-center">
        <div className="bg-grey-300 w-10 h-10 rounded-full flex justify-center items-center">
          {icon}
        </div>
        <span className="ml-2 text-lg font-bold">{title}</span>
      </h3>
      <p className="text-sm leading-7 mt-3">{description}</p>
      <span className="text-xs opacity-50">{textNote}</span>
    </div>
  );
};

export default IntroductionContent;
