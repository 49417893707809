import { StateCreator } from 'zustand';

import { ContactStoreDef } from '@/models/inquiry.types';

export const contact: StateCreator<ContactStoreDef> = (set) => ({
  contact: null,
  isSubmitInquirySuccess: null,
  setContact: (value) => set({ contact: value }),
  clearContact: () => set({ contact: null }),
  clearAllContactInfo: () =>
    set({ contact: null, isSubmitInquirySuccess: null }),
  setIsSubmitInquirySuccess: (value) => set({ isSubmitInquirySuccess: value }),
});
