import React from 'react';

import Image from 'next/image';

import Cinemas109Image from '@/assets/images/customers/109-cinemas.png';
import BandaiNamcoImage from '@/assets/images/customers/bandai-namco.png';
import ChallengersParkImage from '@/assets/images/customers/esports-challenger-park.png';
import HMVImage from '@/assets/images/customers/hmv.png';
import KawasakiFrontaleImage from '@/assets/images/customers/kawasaki-frontale.png';
import MontedioImage from '@/assets/images/customers/montedio.png';
import NomuraKogeishaImage from '@/assets/images/customers/nomura-kogeisha.png';
import RioImage from '@/assets/images/customers/rio.png';
import ShinshuBraveWarriorsImage from '@/assets/images/customers/shinshu-brave-warriors.png';
import ShirahamaImage from '@/assets/images/customers/shirahama.png';
import TowerRecordsImage from '@/assets/images/customers/tower-records.png';
import YamajiroNoSatoImage from '@/assets/images/customers/yamajiro-no-sato.png';
import YoshimotoImage from '@/assets/images/customers/yoshimoto.png';
import { ContainerComponent, SectionTitleComponent } from '@/components';

const CUSTOMERS_CONTENT = [
  { image: Cinemas109Image, alt: '109シネマズ' },
  { image: ChallengersParkImage, alt: 'QTnet' },
  { image: TowerRecordsImage, alt: 'TOWER RECORDS' },
  { image: BandaiNamcoImage, alt: 'バンダイナムコ' },
  { image: HMVImage, alt: 'HMV' },
  { image: MontedioImage, alt: 'モンテディオ山形' },
  { image: RioImage, alt: '犬カフェRio' },
  { image: NomuraKogeishaImage, alt: '乃村工藝社' },
  { image: YamajiroNoSatoImage, alt: '山城の郷' },
  { image: YoshimotoImage, alt: 'よしもとエンタメショップ' },
  { image: ShinshuBraveWarriorsImage, alt: '信州ブレイブウォリアーズ' },
  { image: KawasakiFrontaleImage, alt: '川崎フロンターレ' },
  { image: ShirahamaImage, alt: 'ホテル白浜館' },
] as const;

const CustomersSection = () => (
  <section>
    <ContainerComponent size="large" className="py-10 tl:py-12 tl:pb-20">
      <SectionTitleComponent
        id="customers"
        title="CUSTOMERS"
        description="導入企業"
      />
      <ul className="mt-5 tl:mt-10 grid grid-cols-4 tl:grid-cols-10 gap-1 tl:gap-x-0 tl:gap-y-6">
        {CUSTOMERS_CONTENT.map((item) => (
          <li
            key={item.alt}
            className="col-span-2 last:col-start-2 tl:last:col-start-auto tl:first:col-start-2 tl:tenth:col-start-2"
          >
            <Image src={item.image} alt={item.alt} />
          </li>
        ))}
      </ul>
    </ContainerComponent>
  </section>
);
export default CustomersSection;
