import React, { useState } from 'react';

import Image from 'next/image';
import Link from 'next/link';

import IconArrowForward from '@/assets/icons/icon-arrow-forward.svg';
import IconClose from '@/assets/icons/icon-close.svg';
import AsakusaEventBannerPCImage from '@/assets/images/asakusa-event/asakusa-event-banner-pc.png';
import AsakusaEventBannerImage from '@/assets/images/asakusa-event/asakusa-event-banner.png';
import { ContainerComponent } from '@/components';
import { cn } from '@/utils/tailwind';

const FloatAsakusaEventSection = () => {
  const [isShow, setIsShow] = useState(true);

  return (
    <ContainerComponent
      className={cn(
        'fixed w-full bottom-24 tl:bottom-8 tl:!px-0 tl:right-8 tl:w-auto z-10 transition-all duration-300',
        { 'opacity-0 invisible': !isShow }
      )}
    >
      <div className="shadow-asakusa-event rounded-lg relative bg-asakusa-event tl:w-[17.5rem]">
        <Link
          target="_blank"
          className="flex tl:flex-col-reverse"
          href={process.env.NEXT_PUBLIC_ASAKUSA_EVENT_URL as string}
        >
          <div className="py-3 px-4 tl:pb-4 text-blue">
            <h4 className="font-bold text-sm leading-[1.4]">
              つくろう！浅草思い出写真缶
            </h4>
            <p className="mt-1 text-xs leading-normal">
              Snap Drinkを浅草のさまざまな場所で体験！
            </p>
            <p className="font-bold text-xs mt-2 flex items-center">
              特設サイトへ <IconArrowForward className="ml-1" />
            </p>
          </div>
          <Image
            className="w-[7.5rem] tl:hidden ml-auto rounded-r-lg"
            src={AsakusaEventBannerImage}
            alt="asakusa event"
          />
          <Image
            className="hidden tl:block rounded-t-lg"
            src={AsakusaEventBannerPCImage}
            alt="asakusa event"
          />
        </Link>
        <IconClose
          onClick={() => setIsShow(false)}
          className="absolute right-0 top-0 translate-x-1/2 -translate-y-1/2 cursor-pointer"
        />
      </div>
    </ContainerComponent>
  );
};

export default FloatAsakusaEventSection;
