import { useMemo } from 'react';

import IconSnapTitle from '@/assets/icons/scenes/snap-title.svg';
import IconTagTitle from '@/assets/icons/scenes/tag-title.svg';
import ImageScene01 from '@/assets/images/scenes/scene-01.jpg';
import ImageScene02 from '@/assets/images/scenes/scene-02.jpg';
import ImageScene03 from '@/assets/images/scenes/scene-03.png';
import ImageSceneSnap01 from '@/assets/images/scenes/scene-snap-01.jpg';
import ImageSceneSnap02 from '@/assets/images/scenes/scene-snap-02.jpg';
import ImageSceneSnap03 from '@/assets/images/scenes/scene-snap-03.jpg';
import {
  Br,
  ContainerComponent,
  SceneItemComponent,
  SectionTitleComponent,
} from '@/components';
import { SceneItemDataDef } from '@/models/landingpage.types';

const SceneSection = () => {
  const SCENE_LIST: Record<'tagList' | 'snapDrinkList', SceneItemDataDef[]> =
    useMemo(
      () => ({
        tagList: [
          {
            image: ImageScene01,
            imageAlt: '野球選手',
            mainText: 'スポーツ観戦',
            title: (
              <>
                試合のシーンを切り取り、
                <Br />
                試合会場のグッズ売り場で販売
              </>
            ),
            description:
              '試合のクライマックスシーンをラベルにして、その日だけの忘れられない思い出を形に。',
          },
          {
            image: ImageScene02,
            imageAlt: 'コンサート会場',
            mainText: 'コンサート･ライブ',
            title: (
              <>
                コンサートの記念グッズとして
                <Br />
                イベント会場で販売
              </>
            ),
            description:
              '日付や場所とイベントのコンテンツをラベルに入れることで、その日だけの記念グッズを販売。',
          },
          {
            image: ImageScene03,
            imageAlt: 'キャラクターグッズ',
            mainText: 'グッズショップ',
            title: (
              <>
                グッズショップのバラエティを
                <Br />
                大幅強化
              </>
            ),
            description:
              '様々なラベルのドリンクを販売することによって商品のラインナップを強化。',
          },
        ],
        snapDrinkList: [
          {
            image: ImageSceneSnap01,
            imageAlt: 'SnapDrink(スナップドリンク)-いちご狩り',
            mainText: '体験施設',
            title: '特別な体験の思い出をすぐラベル缶として販売',
            description: '体験のその瞬間をお土産としてお持ち帰り頂けます。',
          },
          {
            image: ImageSceneSnap02,
            imageAlt: 'SnapDrink(スナップドリンク)-友人と記念写真',
            mainText: 'レジャー施設',
            title:
              'レジャー施設での思い出をすぐラベル缶としてグッズショップで販売',
            description:
              '最高の笑顔や予期せぬハプニング、その日のベストショットをお持ち帰り頂けます。',
          },
          {
            image: ImageSceneSnap03,
            imageAlt: 'SnapDrink(スナップドリンク)-お花見で記念写真',
            mainText: '宿泊施設',
            title: '大切な人との旅の思い出をすぐラベル缶として販売',
            description:
              '旅行先で撮った写真を宿泊施設で振り返り、思い出をお持ち帰り頂けます。',
          },
        ],
      }),
      []
    );

  return (
    <section>
      <ContainerComponent size="large" className="py-10 tl:py-12">
        <SectionTitleComponent
          id="scene"
          title="SCENE"
          description="活用シーン"
        />
        <div className="mt-5 tl:mt-10">
          <IconTagTitle className="mx-auto w-[118px] tl:w-[154px]" />
          <p className="text-center mt-4 text-lg tl:text-xl font-bold tl:tracking-[1px] !leading-[1.6]">
            企業の有するコンテンツを <Br forOnly="mobile" />
            オリジナルラベルに
          </p>
          <ul className="grid grid-cols-1 pc:grid-cols-3 gap-y-9 pc:gap-x-4 mt-9">
            {SCENE_LIST.tagList.map(
              ({ image, imageAlt, mainText, title, description }, idx) => (
                <li key={idx}>
                  <SceneItemComponent
                    image={image}
                    imageAlt={imageAlt}
                    mainText={mainText}
                    description={description}
                    title={title}
                    number={idx + 1}
                  />
                </li>
              )
            )}
          </ul>
        </div>
        <div className="mt-10">
          <IconSnapTitle className="m-auto" />
          <p className="text-center mt-4 text-lg tl:text-xl font-bold tl:tracking-[1px] !leading-[1.6]">
            お客様のスマホで撮影した写真を
            <Br forOnly="mobile" />
            オリジナルラベルに
          </p>
          <ul className="grid grid-cols-1 pc:grid-cols-3 gap-y-9 pc:gap-x-4 mt-9">
            {SCENE_LIST.snapDrinkList.map(
              ({ image, imageAlt, mainText, title, description }, idx) => (
                <li key={idx}>
                  <SceneItemComponent
                    image={image}
                    imageAlt={imageAlt}
                    mainText={mainText}
                    description={description}
                    title={title}
                    number={idx + 1}
                  />
                </li>
              )
            )}
          </ul>
        </div>
      </ContainerComponent>
    </section>
  );
};

export default SceneSection;
