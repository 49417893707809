import React from 'react';

import cs from 'classnames';

import { IntroductionContentComponent } from '@/components';
import {
  EasyToIntroduceDataDef,
  IntroduceContentDataDef,
} from '@/models/landingpage.types';

type IntroductionInventoryProps = EasyToIntroduceDataDef;

const IntroductionInventory = ({
  mainTitle,
  topContent,
  contents,
  isLast,
}: IntroductionInventoryProps) => {
  const classNamesTitle = cs(
    'flex items-center absolute left-0 top-0 -translate-y-1/2 w-full before:h-1 before:bg-secondary before:mr-2.5 after:h-1 after:bg-secondary after:ml-2.5',
    {
      'before:w-[calc(50%-3.59375rem)] tl:before:w-[calc(50%-4.28125rem)] after:w-[calc(50%-3.59375rem)] tl:after:w-[calc(50%-4.28125rem)]':
        !isLast,
      'before:w-[calc(50%-4.34375rem)] tl:before:w-[calc(50%-5.25rem)] after:w-[calc(50%-4.34375rem)] tl:after:w-[calc(50%-5.25rem)]':
        isLast,
    }
  );

  return (
    <div className="bg-white p-6 tl:pt-8 relative h-full flex flex-col justify-between">
      <h2 className={classNamesTitle}>{mainTitle}</h2>
      {topContent}
      <ul className="mt-8 tl:mt-9">
        {contents.map((item: IntroduceContentDataDef, idx: number) => (
          <li key={idx} className="last:mt-4 pc:last:mt-3">
            <IntroductionContentComponent content={item} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default IntroductionInventory;
