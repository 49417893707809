import React from 'react';

import cs from 'classnames';

type BrProps = {
  forOnly?: 'mobile' | 'tablet' | 'desktop' | 'all';
};

const Br = ({ forOnly = 'all' }: BrProps) => {
  const classNames = cs({
    'tl:hidden': forOnly === 'mobile',
    'hidden tl:block pc:hidden': forOnly === 'tablet',
    'hidden pc:block': forOnly === 'desktop',
  });

  return <br className={classNames} />;
};

export default Br;
