import React from 'react';

import cs from 'classnames';

import IconArrow from '@/assets/icons/icon-down.svg';
import { SelectOptionsDef } from '@/models/inquiry.types';

type SelectProps = {
  options: SelectOptionsDef[];
} & React.SelectHTMLAttributes<HTMLSelectElement>;

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ options, placeholder, className, ...rest }, ref) => (
    <div className="relative">
      <select
        required
        ref={ref}
        defaultValue=""
        {...rest}
        className={cs(
          'w-full h-10 rounded border border-grey-300 bg-gray-200 px-2 text-sm focus:outline-none focus:border-secondary appearance-none',
          className
        )}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options?.map(({ label, value }, idx) => (
          <option key={idx} value={value}>
            {label}
          </option>
        ))}
      </select>
      <IconArrow className="absolute right-3 top-1/2 -translate-y-1/2 pointer-events-none" />
    </div>
  )
);

export default Select;
