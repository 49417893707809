import React from 'react';

import cs from 'classnames';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ type = 'text', className, ...rest }, ref) => (
    <input
      ref={ref}
      type={type}
      {...rest}
      className={cs(
        'w-full h-10 rounded border border-grey-300 bg-gray-200 px-2 text-sm focus:outline-none focus:border-secondary placeholder:text-gray-100 appearance-none',
        className
      )}
    />
  )
);

export default Input;
