import React, { useMemo } from 'react';

import cs from 'classnames';

import ImageAboutDesignSystem from '@/assets/images/what-tag-live-label/about-designsystem.png';
import ImageAboutDrink from '@/assets/images/what-tag-live-label/about-drink.png';
import ImageAboutOriginalLabel from '@/assets/images/what-tag-live-label/about-originallabel.png';
import {
  SectionTitleComponent,
  ContainerComponent,
  ButtonComponent,
  WhatTagLiveLabelItemComponent,
  Br,
} from '@/components';
import { WhatTagLiveLabelDataDef } from '@/models/landingpage.types';
import { handleRedirectNewTab } from '@/utils/redirect';

const WhatTagLiveLabelSection = () => {
  const WHAT_TAG_LIVE_LABEL_DATA: WhatTagLiveLabelDataDef[] = useMemo(
    () => [
      {
        image: ImageAboutDesignSystem,
        imageAlt: 'Web管理画面',
        title: 'ラベルデザインシステム',
        description: (
          <>
            自社のコンテンツ素材 /
            ご自身のスマホで撮影した写真を専用のシステムにアップロードしてラベルを作成。日付や時間、場所、タイトルなどを設定することができます。
          </>
        ),
      },
      {
        image: ImageAboutOriginalLabel,
        imageAlt: 'プリンター出力イメージ',
        title: 'オリジナルラベル',
        description: (
          <>
            ラベルを専用プリンターで印刷。　
            <Br />
            二重シールになっているため、お客様がドリンク缶を購入後にシールとして利用することもできます。
          </>
        ),
      },
      {
        image: ImageAboutDrink,
        imageAlt: 'オリジナル缶',
        title: '専用ドリンク',
        description: (
          <>
            専用缶にラベルを貼り付けて完成。
            <Br />
            その日、その場所だけの特別なドリンクをオリジナルグッズとして簡単に販売することができます。
            <Br />
            フレーバーはレモンスカッシュとウーロン茶の2種。
          </>
        ),
      },
    ],
    []
  );

  return (
    <section className="bg-gray-200">
      <ContainerComponent size="large" className="py-10 tl:py-12">
        <SectionTitleComponent
          id="what-is-tag-live-label"
          title="WHAT’S TAG LIVE LABEL?"
          description="TAG LIVE LABELとは？"
        />
        <ul className="mt-5 tl:mt-10">
          {WHAT_TAG_LIVE_LABEL_DATA.map(
            ({ image, imageAlt, title, description }, idx) => (
              <li
                key={idx}
                className={cs('flex flex-col tl:flex-row items-center', {
                  'mt-6 tl:mt-4': !!idx,
                })}
              >
                <WhatTagLiveLabelItemComponent
                  image={image}
                  imageAlt={imageAlt}
                  title={title}
                  description={description}
                />
              </li>
            )
          )}
        </ul>
        <div className="text-center mt-5 tl:mt-10">
          <ButtonComponent
            btnType="primary"
            icon={true}
            className="w-full tl:max-w-[21.875rem] h-15 text-white relative"
            onClick={handleRedirectNewTab}
          >
            お問い合わせはこちら
          </ButtonComponent>
        </div>
      </ContainerComponent>
    </section>
  );
};

export default WhatTagLiveLabelSection;
