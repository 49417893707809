import React from 'react';

import IconCheck from '@/assets/icons/icon-check.svg';

type CheckboxProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'className'
>;

const Checkbox = (props: CheckboxProps) => (
  <div className="w-[1.5625rem] h-[1.5625rem] relative">
    <input
      type="checkbox"
      id="toggle"
      className="checkbox w-full h-full absolute opacity-0 z-20 cursor-default pc:cursor-pointer"
      {...props}
    />
    <div className="w-full h-full bg-secondary rounded-sm"></div>
    <IconCheck className="checkbox-checked absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 hidden z-10" />
  </div>
);

export default Checkbox;
