import { MetadataItemDef, ScreenName } from '@/models/metadata.types';

const METADATA = {
  top: {
    title: 'TAG LIVE LABEL | SNAP DRINK：ラベル缶',
    description:
      'オリジナルラベル缶をその場で作成・販売できる法人向けサービスです。超小ロット・在庫リスクゼロ・短納期・初期費用0円でご導入いただけます。',
  },
  inquiry: {
    title: 'TAG LIVE LABEL | SNAP DRINK：お問い合わせフォーム',
    description: 'TAG LIVE LABEL, SNAP DRINKのお問い合わせフォームです。',
  },
  policy: {
    title: 'TAG LIVE LABEL | アプリ用プライバシーポリシー',
    description: 'TAG LIVE LABELの店舗アプリ用プライバシーポリシーです。',
  },
} as Record<ScreenName, MetadataItemDef>;

export { METADATA };
