import type { ReactNode } from 'react';
import React from 'react';

import cs from 'classnames';

import Icon from '@/assets/icons/icon-next.svg';

type ButtonProps = {
  className?: string;
  btnType?: 'primary' | 'default';
  isHeader?: boolean;
  children: ReactNode;
  icon?: boolean;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'>;

const Button = ({
  className,
  children,
  btnType = 'primary',
  icon,
  isHeader,
  ...rest
}: ButtonProps) => {
  const classNamesButton = cs(
    'rounded focus-visible:outline-none cursor-default pc:cursor-pointer disabled:cursor-not-allowed shadow-button',
    className,
    {
      'bg-secondary text-white hover:text-secondary font-bold border-secondary border hover:bg-white':
        btnType === 'primary',
      'bg-white disabled:text-gray-100 enabled:hover:bg-gray-500 hover:text-white':
        btnType === 'default',
      'flex items-center pc:shadow-none': isHeader,
    }
  );
  const classNamesIcon = cs({
    'pc:ml-3 absolute pc:static right-6 top-6': isHeader,
    'absolute right-6 top-6': !isHeader,
  });

  return (
    <button className={classNamesButton} {...rest}>
      {children}
      {icon && <Icon className={classNamesIcon} />}
    </button>
  );
};

export default Button;
