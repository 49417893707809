import React from 'react';

import {
  ContainerComponent,
  SectionTitleComponent,
  FaqItemComponent,
} from '@/components';
import { FAQ_LIST } from '@/constants/faq.constants';

const FAQSection = () => (
  <section>
    <ContainerComponent size="large" className="py-10 tl:py-12">
      <SectionTitleComponent
        id="faq"
        title="FAQ"
        description="よくあるご質問"
      />
      <ul className="mt-6 tl:mt-15">
        {FAQ_LIST.map((faqItem, index) => (
          <li
            key={index}
            className="border-t last:border-b border-gray-100 -mx-5 tl:mx-0"
          >
            <FaqItemComponent {...faqItem} />
          </li>
        ))}
      </ul>
    </ContainerComponent>
  </section>
);

export default FAQSection;
