import React from 'react';

import Image from 'next/image';

import { WhatTagLiveLabelDataDef } from '@/models/landingpage.types';

type WhatTagLiveLabelItemProps = WhatTagLiveLabelDataDef;

const WhatTagLiveLabelItem = ({
  image,
  imageAlt,
  title,
  description,
}: WhatTagLiveLabelItemProps) => (
  <>
    <Image src={image} alt={imageAlt} className="tl:w-[18.75rem]" />
    <div className="w-full mt-2 tl:mt-0 tl:ml-7 px-2 tl:px-0">
      <h3 className="text-xl tl:text-2xl font-bold">{title}</h3>
      <p className="text-sm mt-2 tl:mt-4 leading-26">{description}</p>
    </div>
  </>
);

export default WhatTagLiveLabelItem;
