const FAQ_LIST = [
  {
    question: '個人でもこのシステムを利用できますか？',
    answer:
      '本サービスは法人向けのサービスとなっており、法人様の店舗等でシステムを利用し、商品を販売いただくサービスとなります。',
  },
  {
    question: '導入にあたり必要なものはありますか？',
    answer:
      '導入にあたり必要なものとしては、ラベルに使用するコンテンツ・Wi-Fi環境・タブレット端末のご用意をお願いいたします。',
  },
  {
    question: '導入のフローは？',
    answer:
      'HPよりお問い合わせいただくと弊社担当からご連絡させていただきます。その後ご契約となりましたら、弊社からシステム等の貸与や研修を実施させていただき、完了次第営業開始となります。',
  },
  {
    question: '専用ドリンクのフレーバーと栄養成分を教えてください。',
    answer:
      'フレーバーはレモンスカッシュ（350ml）、ウーロン茶（340g）の2種で、各栄養成分は以下の通りです。',
    recipes: [
      {
        name: 'レモンスカッシュ（350ml）',
        ingredients:
          '糖類（果糖ぶどう糖液糖（国内製造）、砂糖）、レモン果汁／炭酸、ビタミンＣ、酸味料、香料、ベニバナ色素、パントテン酸カルシウム、ビタミンＢ６、カロチン色素',
        ingredientsAndCharacteristics: [
          ['エネルギー', '40kcal'],
          ['たんぱく質', '0g'],
          ['脂質', '0g'],
          ['炭水化物', '10.0g'],
          ['食塩相当量', '0.05g'],
          ['パントテン酸', '0.1～1.4mg'],
          ['カリウム', '10mg未満'],
          ['リン', '1mg未満'],
          ['ビタミンＢ６', '0.3mg'],
          ['ビタミンＣ', '160mg'],
        ],
      },
      {
        name: 'ウーロン茶（340g）',
        ingredients: '烏龍茶（中国福建省）／ビタミンＣ',
        ingredientsAndCharacteristics: [
          ['エネルギー', '0kcal'],
          ['たんぱく質', '0g'],
          ['脂質', '0g'],
          ['炭水化物', '0g'],
          ['食塩相当量', '0.03g'],
          ['ポリフェノール', '45mg'],
          ['カフェイン', '約20mg'],
          ['カリウム', '約10mg'],
          ['リン', '10mg未満'],
        ],
      },
    ],
  },
  {
    question: '専用ドリンクの賞味期限はどのくらいですか？',
    answer: '製造から12か月です。',
  },
  {
    question: 'ラベル上では、何を設定することができますか？',
    answer:
      'ラベル左面の画像とメインテキスト、右面の日時、場所、コンテンツ名が設定できます。',
  },
  {
    question: 'どのようなオペレーションで販売するものですか？',
    answer:
      'お客様の注文に応じてラベルを印刷し、ラベルを専用缶に貼り付けて販売する形になります。',
  },
];

export { FAQ_LIST };
