import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

import React from 'react';

import Image from 'next/image';
import { Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import BannerImagePC01 from '@/assets/images/section-banner/banner-pc-01.png';
import BannerImagePC02 from '@/assets/images/section-banner/banner-pc-02.png';
import BannerImageSP01 from '@/assets/images/section-banner/banner-sp-01.png';
import BannerImageSP02 from '@/assets/images/section-banner/banner-sp-02.png';
import BannerImageTL01 from '@/assets/images/section-banner/banner-tl-01.png';
import BannerImageTL02 from '@/assets/images/section-banner/banner-tl-02.png';

const BannerSection = () => (
  <section>
    <Swiper
      loop
      speed={1000}
      effect="fade"
      modules={[Autoplay, EffectFade]}
      autoplay={{ delay: 6000, disableOnInteraction: false }}
    >
      <SwiperSlide className="bg-gray-200 !flex justify-center">
        <Image
          priority
          className="hidden pc:block w-auto max-w-none h-[34.4375rem]"
          src={BannerImagePC01}
          alt="TagLiveLabel(タグライブラベル)-お客様の体験を特別な1本で"
        />
        <Image
          priority
          className="hidden tl:block pc:hidden"
          src={BannerImageTL01}
          alt="TagLiveLabel(タグライブラベル)-お客様の体験を特別な1本で"
        />
        <Image
          priority
          className="tl:hidden"
          src={BannerImageSP01}
          alt="TagLiveLabel(タグライブラベル)-お客様の体験を特別な1本で"
        />
      </SwiperSlide>
      <SwiperSlide className="bg-[#0078de] !flex justify-center">
        <Image
          priority
          className="hidden pc:block w-auto max-w-none h-[34.4375rem]"
          src={BannerImagePC02}
          alt="SnapDrink(スナップドリンク)-お客様の体験を特別な1本で"
        />
        <Image
          priority
          className="hidden tl:block pc:hidden"
          src={BannerImageTL02}
          alt="SnapDrink(スナップドリンク)-お客様の体験を特別な1本で"
        />
        <Image
          priority
          className="tl:hidden"
          src={BannerImageSP02}
          alt="SnapDrink(スナップドリンク)-お客様の体験を特別な1本で"
        />
      </SwiperSlide>
    </Swiper>
  </section>
);

export default BannerSection;
