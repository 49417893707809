import React from 'react';

import cs from 'classnames';

type ContainerProps = {
  className?: string;
  size?: 'small' | 'large';
  children: React.ReactNode;
};

const Container = ({ size = 'small', className, children }: ContainerProps) => {
  const classNames = cs(className, 'px-5 tl:px-12 mx-auto', {
    'pc:max-w-[51.625rem]': size === 'small',
    'pc:max-w-[67.25rem]': size === 'large',
  });
  return <div className={classNames}>{children}</div>;
};

export default Container;
