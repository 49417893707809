import React, { LabelHTMLAttributes, useEffect, useState } from 'react';

import cs from 'classnames';
import { FieldError } from 'react-hook-form';

import IconError from '@/assets/icons/icon-error.svg';

type FormItemProps = {
  label: string;
  required?: boolean;
  input: React.ReactNode;
  className?: string;
  error?: FieldError | undefined;
} & Omit<LabelHTMLAttributes<HTMLLabelElement>, 'className'>;

const FormItem = ({
  label,
  required,
  className = '',
  input,
  error,
  ...rest
}: FormItemProps) => {
  const [localError, setLocalError] = useState<FieldError | undefined>();

  useEffect(() => {
    error?.message && setLocalError(error);
  }, [error]);

  return (
    <div className={cs(className, 'relative')}>
      <label
        {...rest}
        className={cs('text-sm mb-0.5 inline-block', {
          'after:inline-block after:content-["*"] after:text-red after:ml-0.5':
            required,
        })}
      >
        {label}
      </label>
      <div>{input}</div>
      <span
        className={cs(
          'flex items-center text-red text-11 leading-4 transition-opacity opacity-0 duration-300 absolute -bottom-0.5 translate-y-full',
          { 'opacity-100': !!error }
        )}
      >
        <IconError className="mr-0.5" />
        {localError?.message}
      </span>
    </div>
  );
};

export default FormItem;
