import { ResultTitleComponent } from '@/components';

type ResultProps = {
  buttonClose?: JSX.Element;
  title: string;
  description: JSX.Element;
};

const Result = ({ buttonClose, title, description }: ResultProps) => (
  <>
    <ResultTitleComponent title={title} />
    <p className="mt-5 tl:mt-10 text-center leading-[1.875rem]">
      {description}
    </p>
    {buttonClose}
  </>
);

export default Result;
