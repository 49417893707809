import React from 'react';

import Image from 'next/image';

import { SceneItemDataDef } from '@/models/landingpage.types';

type SceneItemProps = SceneItemDataDef;

const SceneItem = ({
  image,
  imageAlt,
  mainText,
  title,
  description,
  number,
}: SceneItemProps) => (
  <div className="border border-secondary rounded-lg p-5 relative h-full">
    <span className="flex-centered font-gothic-bold text-22 font-bold text-white bg-secondary w-11 h-11 rounded-full absolute -translate-y-1/2 top-0 left-1/2 -translate-x-1/2 pt-[0.3125rem]">
      {number?.toString().padStart(2, '0')}
    </span>
    <h3 className="font-bold text-xl tl:text-2xl text-center mt-3 tl:mt-5 !leading-[1.6] tracking-[1px]">
      {mainText}
    </h3>
    <div className="mt-3 tl:mt-5 tl:flex pc:block">
      <Image src={image} alt={imageAlt} className="tl:w-[17.25rem]" />
      <div className="px-2 tl:px-0 pc:px-2 mt-4 tl:mt-0 pc:mt-4 tl:ml-6 pc:ml-0">
        <h4 className="text-base leading-26 font-bold">{title}</h4>
        <p className="text-sm leading-26 mt-2">{description}</p>
      </div>
    </div>
  </div>
);

export default SceneItem;
