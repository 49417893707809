import React from 'react';

import cs from 'classnames';

type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ rows = 5, className, ...rest }, ref) => (
    <textarea
      ref={ref}
      rows={rows}
      {...rest}
      className={cs(
        'w-full rounded border border-grey-300 bg-gray-200 px-2 py-[0.59375rem] text-sm focus:outline-none focus:border-secondary placeholder:text-gray-100 appearance-none',
        className
      )}
    />
  )
);

export default TextArea;
