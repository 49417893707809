import { FaqRecipesDef } from '@/models/faq.types';

import FaqRecipeItem from '../FaqRecipeItem';

type FaqRecipeListProps = { recipes: FaqRecipesDef };

const FaqRecipeList = ({ recipes }: FaqRecipeListProps) => (
  <ul className="mt-4 grid grid-cols-faq-recipe gap-4">
    {recipes.map((recipe) => (
      <FaqRecipeItem key={recipe.name} recipe={recipe} />
    ))}
  </ul>
);

export default FaqRecipeList;
